* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html {
  font-family: $primaryFont;
  color: $primaryColor;
}

body {
  background: #ececec;
  overflow-x: hidden;
}

