.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 100px;
  padding: 14px;
  background: $blue;
  color: #fff;
  text-align: center;
  pointer-events: none;
  border-radius: 3px;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;


  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -10px;
    border: 10px solid transparent;
    border-top-color: $blue;
  }

  &.Tablet {
    background: $purple;

    &:before {
      border-top-color: $purple;
    }
  }

  &.Mobile {
    background: $green;

    &:before {
      border-top-color: $green;
    }
  }


  .icon {
    font-size: 15px;
    margin-right: 8px;
    position: relative;
    top: 3px;
  }



}



  .chartist-tooltip.tooltip-show {
    opacity: 1; }

.ct-area, .ct-line {
  pointer-events: none; }


.chartist-tooltip-meta {
  font-size: 12px;
}
.chartist-tooltip-value {
  display: block;
  font-size: 24px;
}
