.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.icon-opencities-icon {
  width: 0.67578125em;
}

.icon-opencities-logo {
  width: 3.640625em;
}
