@mixin reset-list () {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@mixin reset-gutters () {

  padding-left: $gutter;
  padding-right: $gutter;
}

@mixin icomoon () {

  /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
