.panel-tabs {
  display: none;
  
  &__list {
    @include reset-list();
    font-size: 0;
    display: inline-block;
  }

  &__item {
    display: inline-block;
    &.active .panel-tabs__link {
      background: #fff;
      color: #101010;
      border: 0;

      @include bp(big) {
        background: #fff;
      }

    }

    @include bp(big) {
      &:last-child .panel-tabs__link {
        border-radius: 0 3px 0 0;
        padding-left: 28px;
        padding-right: 28px;
      }

      &:first-child .panel-tabs__link {
        padding-right: 28px;
        padding-left: 28px;
      }
    }

    
  }

 

  &__link {
    color: rgba(0,0,0,0.4);
    font-size: 18px;
    line-height: 1;
    margin: 0;
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
    background: #e1e1e1;    

    @include bp(small) {
      font-size: 22px;
    }

    @include bp(medium) {
      font-size: 13px;
      padding: 12px;
      height: 40px;
    }

    @include bp(big) {
      height: auto;
      font-size: 24px;
      padding: 0;
      padding: 26px 0;
    }

     @include bp(bigger) {
      height: auto;
      font-size: 26px;
      padding: 0;
      padding: 25px 0;
    }
  }

  .panel__help {
     float: right;

     @include bp(medium) {
       left: 0;
       margin-right: 10px;
     }

     @include bp(big) {
       margin-right: 19px;
       top: 26px;
     }

     @include bp(bigger) {
       float: none;
       position: relative;
       top: 2px;
       left: 14px;
     }
     
   }

  .live-badge {
    position: relative;
    top: 2px;  

    @include bp(big) {
      top: 23px;
    }
  }



  @include bp(medium) {
    display: block;
    padding-top: 35px;
    margin-right: -10px;
  }
  
  @include bp(big) {
    margin-right: 0;
    padding-top: 0;
  }

  @include bp(huge) {
    display: none;
  }
}

.panel {


  position: relative;
  @include bp(medium) {

    &-group .panel {
      margin-right: -10px;
      display: none;

      &.active {
        display: block;
      }

    }

    &--3 {
      margin-left: -10px;
    }


    &--1 {
      .panel__top{
        display: none;
      }
    }

    &--2 {
      .panel__top{
        display: none;
      }
    }
  }

  @include bp(big) {
    &-group .panel {
      margin-right: -0;
    }

    &--3 {
      margin-left: 0;
    }
  }

  @include bp(huge) {

    &-group .panel {
      display: block;
    }

    &--1 {
      .panel__top{
        display: block;
      }
    }

    &--2 {
      .panel__top{
        display: block;
      }
    }
  }

  &__top {
    margin-top: 35px;

    

    @include bp(medium) {
      height: 40px;
      margin-top: 35px;
    }

    @include bp(big) {
      height: auto;
      margin: 0;
    }

    @include bp(huge) {
      .live-badge {
        margin-top: 23px;
      }
    }
  }

  &__title {
    font-size: 18px;
    line-height: 1;
    margin: 0 0 18px;
    font-weight: 400;
    display: inline-block;

    @include bp(small) {
      font-size: 22px;
    }

    @include bp(medium) {
      font-size: 18px;
      line-height: 18px;
      height: 40px;
      margin: 0;
    }

    @include bp(big) {
      height: auto;
      font-size: 30px;
      line-height: 1;
      margin: 23px 0;
    }
  }

  &__help {
    display: inline-block;
    position: relative;
    top: 3px;
    left: 5px;
    color: $primaryColor;

    @include bp(big) {
      top: 1px;
      left: 10px;
    }
  }
}

.icon-help {
  font-size: 20px;
  stroke: #a3b5b9;
  display: none;
}