.chart {
  background: rgba(255,255,255,0.5);
  padding-top: 30px;
  margin-left: -$gutter;
  margin-right: -$gutter;
  margin-bottom: 20px;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.08);
  transition: background 600ms;

  &.created {

    background: #fff;
  }

  .ct-area {
  fill: transparent;
}

  @include bp(medium) {
    padding: 40px 43px 0 0;
    margin: 0 0 20px;
    min-height: 503px;
  }

  @include bp(big) {
    margin-bottom: 40px;
    min-height: 474px;
  }

  &__title {
    margin: 0 0 20px 20px;
    font-size: 23px;
    line-height: 1;
    font-weight: 300;
    color: #101010;

    @include bp(small) {
      font-size: 26px;
    }

    @include bp(medium) {
      font-size: 42px;
      margin-left: 48px;
    }

    @include bp(big) {
      display: inline-block;
    }

    &-icon {
      stroke: $darkblue;
      font-size: 21px;
      margin-right: 8px;
      position: relative;
      top: 2px;

      @include bp(small) {
        top: 1px;
      }

      @include bp(medium) {
        font-size: 32px;
      }
    }
  }
}

.ct-chart {
  cursor: pointer;
}


/*** Chartist Style Overrides ***/

.ct-line {
  stroke-width: 1px;
}


.ct-series-a,
.ct-series-b,
.ct-series-c, {
  .ct-area {
    fill: transparent;
  }

  .ct-point {
    stroke: $blue;
  }

  .ct-circle {
    stroke: $blue;
    fill: $blue;
    stroke-width: 1;
    stroke: #fff;
    opacity: 0;

    transition: opacity 700ms ease-out;

    &:nth-of-type(8n+1) {
      opacity: 1;
    }

    &:first-of-type {
      opacity: 1;
    }

    @include bp(medium) {

      &:nth-of-type(8n+1) {
        opacity: 0;
      }

      &:nth-of-type(4n+1) {
        //opacity: 1;


        .draw-circles & {
          opacity: 1;
        }


      }

/*        &:nth-of-type(8n+1) {
         opacity: 1;
       } */
    }

    .draw-circles & {
      .hover--active & {



        transition: none;
        opacity: 0.15;
       }

       &.active {
        opacity: 1.0;
      }

    }

   


  }

  .ct-line {
    stroke: $blue;
  }
}

.ct-series-b {
  .ct-circle {
    fill: $purple;
  }

   .ct-line {
    stroke: $purple;
  }
}

.ct-series-c {
  .ct-circle {
    fill: $green;
  }

   .ct-line {
    stroke: $green;
  }
}

.ct-grid {

  stroke: #f3f2f2;
  stroke-dasharray: none;

  &.ct-horizontal {
    opacity: 0;


    transition: opacity 700ms ease-out;

    .hover--active & {
      opacity: 1;
       transition: opacity 300ms ease-in;
    }

    &--first {
      opacity: 1;
      stroke: #d7d5d5;
    }
  }
  
  .draw-lines & {
    &.ct-vertical {
      &--first {
        opacity: 1;
        stroke: #d7d5d5;
      }
    }
  }
  

  &.active {
    stroke: rgba(0,0,0,0.25);
  }

}

.ct-label {

  font-weight: 500;

  &.ct-horizontal.ct-end {
    width: 42px !important;
    position: relative;
    left: -21px;
    text-align: center;
    display: block;
    color: rgba(0,0,0,0.4);
    font-size: 9px;


    @include bp(medium) {
      font-size: 12px;
      top: 11px;
    }
  }

   &.ct-vertical.ct-start {
    color: rgba(0,0,0,0.3);
    font-size: 9px;
    position: relative;
    top: 4px;


    @include bp(medium) {
      font-size: 12px;
      top: 6px;
      left: -2px;
    }
  }
}

.ct-labels {

 foreignObject:nth-of-type(24n+0) {
   .ct-label {
     color: $blue;
   }
 }
}

/*** Toggle Chart List ***/

.ch-list {
  @include reset-list();
  font-size: 0;
  padding-left: 20px;
  margin-bottom: 5px;

  @include bp(medium) {
    padding-left: 50px;
  }

  @include bp(big) {
    padding: 0;
    float: right;
    margin-right: -9px;
  }
  &__item {
    display: inline-block;

    &:first-child {
      border-right: 1px solid #eae9e9;
      padding-right: 14px;
      margin-right: 16px;

      @include bp(big) {
        padding-right:  7px;
      }
    }
  }

  &__link{
    text-decoration: none;

    .icon {
      transition: fill 600ms;

    }

    &:hover .icon {
      fill: rgba(0,0,0,0.4);
    }

    &.active {
      .icon-chart {
        fill: $blue;
      }

      .icon-desktop {
        fill: $blue;
      }

      .icon-tablet {
        fill: $purple;
      }

      .icon-phone {
        fill: $green;
      }
    }
  }

  &__device {
    line-height: 1;
    display: inline-block;
    font-size: 0;

    .ch-list--devices & {
      padding-right: 12px;

      @include bp(small) {
        padding-right: 16px;
      }

      &:last-child {
        padding: 0;

        @include bp(big) {
          padding: 0 18px;
        }
      }
    }

    @include bp(big) {
      padding: 0 18px;
      text-align: center;
    }
  }

  &__lbl {
    font-size: 9px;
    font-weight: bold;
    color: rgba(0,0,0,0.5);
    position: relative;
    top: -7px;

    @include bp(small) {
      font-size: 12px;
    }

    @include bp(big) {
      display: block;
      text-align: center;
      top: 0;
      margin-top: 8px;
      font-size: 10px;
    }

  }

  .icon {
    font-size: 22px;
    fill: #cfcfcf;
    margin-right: 8px;

    @include bp(big) {
      margin: 0;
      font-size: 38px;
    }
  }

  @include bp(big) {
    .icon-tablet {
      font-size: 32px;
      position: relative;
      top: -3px;
    }

    .icon-phone {
      font-size: 32px;
      position: relative;
      top: -3px;
    }
  }
}

/*** Live Badge ***/

.live-badge {
  float: right;
  color: #fff;
  background: #d10f41;
  font-size: 10px;
  padding: 0 22px 0 10px;
  line-height: 21px;
  height: 21px;
  border-radius: 3px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;

  @include bp(big) {
    font-size: 12px;
    padding: 0 22px 0 10px;
    height: 25px;
    line-height: 25px;
  }

  &:after {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: 9px;
    right: 9px;
    font-weight: bold;
    animation: flickerAnimation 3s infinite;

    @include bp(big) {
      width: 5px;
      height: 5px;
      top: 10px;
    }
  }
}

/*** Device Icons ***/

.icon-desktop {
  color: $blue;
  font-size: 24px;
}

.icon-tablet {
  color: $purple;
  font-size: 21px;
}

.icon-phone {
  color: $green;
  font-size: 21px;
}



/*** Visitors Card ***/

.v-card {

  background: #fff;
  padding: 26px;
  position: relative;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.08);
  margin-bottom: 20px;

  @include bp(medium) {
    margin-right: -10px;
    margin-bottom: 0;
  }


  @include bp(big) {
    height: 281px;
    margin-bottom: 34px;
    margin-right: 0;
  }

  @include bp(huge) {
    padding: 25px 36px 36px 36px;
  }

  &__top {

    @include bp(big) {
      margin-bottom: 6px;
    }

    @include bp(huge) {
      margin-bottom: -8px;
    }
  }

   &__title {
    margin: 0;
    display: inline-block;
    font-size: 18px;
    color: #000;
    line-height: 18px;

    @include bp(big) {
      font-size: 24px;
      line-height: 24px;
    }


    span {
      color: $primaryColor;
      display: block;
      font-size: 14px;

      @include bp(medium) {
        display: inline;
      }

      @include bp(big) {

        display: block;
        font-size: 16px;
        position: relative;
        top: 1px;
      }
    }
  }

  &__total {

    display: inline-block;
    vertical-align: top;
    vertical-align: text-top;
    font-size: 100px;
    line-height: 1;
    color: $blue;
    font-weight: 500;
    letter-spacing: -4px;
    float: left;



    @include bp(small) {
      font-size: 125px;

      .b-fix & {
        position: relative;
        top: 10px;
      }

    }

    @include bp(huge) {
      font-size: 150px;
    }
  }

  &__body {
    @include clearfix();
  }

  &-list {

    @include reset-list();
    display: inline-block;
/*     float: right; */
    margin-left: 27px;
/*     margin-top: 14px; */

    @include bp(small) {
      margin-top: 20px
    }

    @include bp(big) {
      margin-left: 16px;
    }

    @include bp(bigger) {
      margin-left: 35px;
    }

    @include bp(huge) {
      position: relative;
      top: 12px;

    }

    &__item {
      margin-bottom: 15px;

      .b-fix & {
        margin-bottom: 17px;
      }
    }

    &__icon {
      width: 24px;
      display: inline-block;
      text-align: center;
      float: left;
      margin-right: 12px;
    }

    &__lbl {
      position: relative;
      top: 0;
      line-height: 1;

      .b-fix & {
        top: 2px;
      }
    }

  }

  &-bar {
    clear: both;

    @include clearfix();
    border-radius: 4px;
    overflow: hidden;
    margin-top: 17px;

    @include bp(huge) {
      margin-top: 12px;
    }

    &__item {
      height: 17px;
      float: left;
      transition: width 1000ms ease-out;
      visibility: hidden;
      &.active {
        visibility: visible;
      }

    }

    &--desktop {
      background: $blue;
    }

    &--tablet {
      background: $purple;
    }

    &--phone {
      background: $green;
    }
  }

  [data-devices="3"] {

    .v-card-bar__item:nth-of-type(1) {
      border-right: 1px solid #fff;
    }

    .v-card-bar__item:nth-of-type(2) {
      border-right: 1px solid #fff;
    }

  }

  [data-devices="2"] {

    .v-card-bar__item.active:nth-of-type(1) {
      border-right: 1px solid #fff;
    }

  }

  [data-devices="1"] {

   
  }

}

/*** Contact Hours ***/

.contact {
  background: $green;
  color: #fff;
  padding: 20px 20px 20px 24px;
  position: relative;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.08);

  @include bp(medium) {
    height: 231px;
    padding-top: 24px;
    margin-left: -10px;
  }

  @include bp(big) {
    height: 159px;
    padding-top: 24px;
    padding-left: 34px;
    margin-left: 0;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    margin: 0 0 7px;

    @include bp(medium) {
      font-size: 18px;
    }

    @include bp(big) {
      font-size: 20px;
      margin-bottom: 11px;

      .b-fix & {
        margin-bottom: 16px;
      }
    }

    .b-fix & {
      margin-bottom: 12px;
    }
  }

  &__icon {
    font-size: 90px;
    position: absolute;
    right: 20px;
    top: 24px;
    opacity: 0.3;
    display: none;

    @include bp(small) {
      display: block;
    }

    @include bp(medium) {
      font-size: 60px;
      top: 84px;

    }

    @include bp(big) {
      display: none;
    }

    @include bp(bigger) {
      display: block;
      font-size: 70px;
      top: 57px;
    }

    @include bp(huge) {
      font-size: 99px;
      top: 27px;
      right: 42px;
    }
  }

  &-list {
    @include reset-list();
    font-size: 0;
  }

  &__item {
    display: inline-block;
    position: relative;


    &:first-child {
      margin-right: 35px;
    }
  }

  &__value {
    font-size: 48px;
    line-height: 48px;
    display: block;
    letter-spacing: 0;
    margin-bottom: 4px;
    font-weight: 400;

    @include bp(medium) {
      font-size: 72px;
      line-height: 72px;
      margin-top: 18px;
    }

    @include bp(big) {

      font-size: 48px;
      line-height: 48px;
      display: block;
      letter-spacing: 0;
      margin-bottom: 4px;
      font-weight: 400;
      margin-top: 0;
    }

    @include bp(huge) {
      font-size: 55px;
      margin-bottom: 11px;
    }
  }

   &__lbl {
    font-size: 14px;
    color: #c3ff88;
    text-transform: uppercase;
    display: block;
    text-align: center;
  }

  &__divider {
    font-size: 40px;
    line-height: 50px;
    font-family: serif;
    position: absolute;
    top: 2px;
    color: #87d836;
    right: -21px;
    text-align: center;
    width: 10px;

    @include bp(medium) {
      font-size: 60px;
      top: 24px;
    }

    @include bp(big) {
      top: -3px;
    }

    @include bp(huge) {
      top: 0;
    }
  }
}



.chart--1 .ct-series-a .ct-area {
  fill: url(#linear);

  fill-opacity: 0.1;
}

