.footer {
  padding-top: 34px;
  color: lighten($primaryColor,30%);
  font-size: 14px;
  text-align: center;
  font-size: 12px;
  position: relative;

  @include bp(big) {
    font-size: 14px;
    padding: 80px 0 70px;	
  }

  .icon {
  	font-size: 30px;
  	position: absolute;
  	top: -6px;
  	left: 50%;
  	margin-left: -15px;
  	display: none;

  	@include bp(medium) {
  	  display: block;
  	}

  	@include bp(big) {
	  font-size: 40px;
	  margin-left: -20px;	
    }
  }

  &--copyright {
  	float: left;
  }

  &__link {

    color: lighten($primaryColor,30%);
  	float: right;
  	font-weight: 500;
  	text-decoration: none;


  } 

}
