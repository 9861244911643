/************************
	   Layout;
************************/

$gutter: 20px;
$max-width: 1820 + ($gutter * 2);


/************************
	   Screen Sizes
************************/

$sc-small: 375px;
$sc-medium: 768px;
$sc-large: 1024px;
$sc-big: 1280px;
$sc-bigger: 1440px;
$sc-huge: 1900px;


/************************
     Color
************************/
$grey: #63696b;
$blue: #00b0db;
$darkblue: #0073af;
$green: #5fbe00;
$purple: #8443c6;

$primaryColor: $grey;


/************************
	   Fonts
************************/

$primaryFont: 'aktiv-grotesk', sans-serif;
