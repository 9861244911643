.header {
  padding: 16px 0;

  @include bp(medium) {
    padding: 30px 0;
  }
}

.icon--home {
  fill: $blue;
  font-size: 44px;
  display: none;

  @include bp(medium) {
    display: inline;
    float: left;
  }

  &-small {
    font-size: 28px;
    fill: $blue;
    float: left;

    @include bp(medium) {
      display: none;
    }
  }
}

.logo {

  display: inline-block;

  &__subhead {
    color: #a3b5b9;
    font-size: 18px;
    left: 5px;
    top: 4px;
    line-height: 18px;
    position: relative;

    @include bp(medium) {
      top: 15px;
      left: 0;
       margin-left: 15px;
      padding-left: 20px;

      &:before {
        content: "";
        width: 2px;
        height: 23px;
        background: #c9d1d3;
        position: absolute;
        top: -3px;
        left: 0;
      }
    }
  }
}

.org {
  float: right;

  &__title {
    display: inline;
    font-size: 14px;
    line-height: 1;
    margin-right: 8px;
    position: relative;
    top: 3px;
    font-weight: 500;
    transition: opacity 400ms;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    @include bp(medium) {
      font-size: 18px;
      top: 15px;
      margin-right: 16px;
    }
  }

  &__icon {
    float: right;
    color: $green;
    font-size: 18px;
    position: relative;
    top: 4px;

    @include bp(medium) {
      font-size: 28px;
      top: 10px;
    }
  }
}