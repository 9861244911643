@mixin bp($point) {

  @if $point == huge {
    @media (min-width: $sc-huge) { @content; }
  }

  @else if $point == bigger {
    @media (min-width: $sc-bigger) { @content; }
  }

  @else if $point == big {
    @media (min-width: $sc-big) { @content; }
  }

  @else if $point == large {
    @media (min-width: $sc-large) { @content; }
  }
  @else if $point == medium {
    @media (min-width: $sc-medium) { @content; }
  }

  @else if $point == small {
    @media (min-width: $sc-small)  { @content; }
  }
}
