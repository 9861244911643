@keyframes flickerAnimation {
  0%   { opacity:1; }
  13%  { opacity:0; }
  26%  { opacity:1; }
  39%  { opacity:0; }
  52%  { opacity:1; }
  100% { opacity:1; }
}

@keyframes arrowAnimationUp {
  0%   { opacity:0; transform: translate3d(0, 100%, 0); }
  15%  { opacity:1; transform: translate3d(0, 0, 0); }
  85%  { opacity:1; }
  100% { opacity:0; }
}

@keyframes arrowAnimationDown {
  0%   { opacity:0; transform: translate3d(0, -100%, 0); }
  15%  { opacity:1; transform: translate3d(0, 0, 0); }
  85%  { opacity:1; }
  100% { opacity:0; }
}


@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}



.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;

  animation-duration: 400ms;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}



.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;

  animation-duration: 400ms;
  animation-fill-mode: both;
}


@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 500ms;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 400ms;
  animation-fill-mode: both;
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 200%, 0);
    transform: translate3d(0, 200%, 0);
  }
}


.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;

  animation-timing-function: ease-in;

  animation-duration: 500ms;
  animation-fill-mode: both;
}

