.card {

  padding: 24px 0 17px 24px;
  background: #fff;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #e7e7e7;
  height: 93px;
  transform: translateZ(0);

  @include bp(big) {
    padding: 35px 0 30px 28px;
    height: 127px;
  }
  
  &--popular {
    padding-left: 70px;


    @include bp(small) {
      padding-left: 86px;
    }

    @include bp(big) {
      padding-left: 122px;
    }
  }

  &:last-child {
    border: none;
  }
  
  &__title {
    font-size: 15px;
    margin: 0 0 14px;
    line-height: 1;
    white-space:nowrap;
    position: relative;

    @include bp(big) {
      font-size: 18px;
      margin-bottom: 18px;
    }

    &:after {
      width: 150px;
      height: 24px;
      content:"";
      position: absolute;
      display: block;
      right: 0;
      top: 0;
      background: linear-gradient(90deg,rgba(255,255,255,0), rgba(255,255,255,0.5),rgba(255,255,255,0.8), rgba(255,255,255,1) 90%);
      pointer-events: none;
    }
  }

  &__total {
    position: absolute;
    width: 70px;
    top: 20px;
    left: 0;
    font-size: 21px;
    text-align: center;
    color: $darkblue;
    font-weight: bold;

    span {
      font-size: 8px;
      font-weight: 400;
      display: block;
      margin-top: 0;
      text-transform: uppercase;
      color: lighten($primaryColor,40%);

      
      @include bp(big) {
        font-size: 10px;
      }
    }

    @include bp(small) {
      width: 86px;
    }

    @include bp(big) {
      font-size: 24px;
      width: 122px;
      top: 30px;
    }
  }

  &-list {
    border-radius: 3px;
    //overflow-y: hidden;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.08);
    background: #fff;
    position: relative;

    .panel--1 & {
      background: none;
      box-shadow: none;
    }
  }

  &__nbr {

  
  }

  &__lbl {

   
  }

  .icon {
    
  }

  &__meta {
    position: relative;
  }

  &__icon--2 {
    margin-left: 28px;

    @include bp(medium) {
      margin-left: 35px;
    }
  }

  &__msg {
    margin-left: 5px;
    font-size: 12px;
    line-height: 1;
    position: relative;
    top: -4px;
    display: inline-block;

    &--2 {
      margin-right: 28px;

      @include bp(big) {
        margin-right: 35px;
      }
    }

    @include bp(big) {
      font-size: 16px;
      top: -5px;
    }
  }

  &--users .card__msg {
    @include bp(big) {
      top: -3px;
    }
  }

  .icon-tablet {
    font-size: 18px;
    @include bp(big) {
      font-size: 21px;
    }
  }

  .icon-phone {
    font-size: 17px;
    @include bp(big) {
      font-size: 21px;
    }
  }

  .icon-desktop {
    font-size: 18px;
    @include bp(big) {
      font-size: 24px;
    }
  }

  .icon-users {
    font-size: 15px;
    stroke: $blue;

    @include bp(big) {
      font-size: 18px;
    }
  }

  &:first-child {

    &__nbr {
      color: $blue;
      font-weight: bold;
      font-size: 21px;
      line-height: 1;
    }
  }

}

.card--first {
  border-bottom: 0;
  margin-bottom: 20px;
  box-shadow: 1px 10px 20px rgba(0,0,0,0.07);

  

  @include bp(big) {
    padding: 36px 36px 20px 28px;
    height: 127px;
    margin-bottom: 40px;

    &.card--popular {
      padding-left: 122px;
    }
  }

  .card__title {
    font-size: 18px;
    color: $darkblue;
    font-weight: 700;

    @include bp(big) {
      //font-size: 18px;
      white-space: normal;
      //line-height: 1.2;
      //margin-bottom: 15px;

      &:after {
        display: none;
      }
    }
  }

  .card__nbr--big {
    color: $blue;
    font-size: 21px;
    font-weight: bold;
    margin-right: 2px;

    @include bp(big) {
      font-size: 26px;
    }
  }

  .card__msg {
    font-size: 14px;
    //margin-left: 10px;
    //top: -2px;


    @include bp(big) {
      font-size: 16px;
      //top: -6px;
    }
  }

  .icon-users {
    font-size: 18px;
    position: relative;
    top: -1px;

    @include bp(big) {
      font-size: 24px;
      top: 1px;
    }
  }

  .card__total {
    color: $blue;
    font-size: 30px;
    line-height:1;

    @include bp(big) {
      font-size: 36px;
    }

    span {
      font-size: 10px;
      font-weight: 400;
      display: block;
      margin-top: 4px;
      text-transform: uppercase;
      color: lighten($primaryColor,40%);

      
      @include bp(big) {
        font-size: 12px;
      }
    }
  }
}

$smallCardOffset: -93px;
$bigCardOffset: -126px;

.card-list--live {
  
  
  // Todo
  // Do the same thing on smaller screens,
  // but just kill the transitions

  //transition: none;

  .card:first-child {
    display: none;
  }



  @include bp(big) {

    .card:first-child {
      display: block;

      transform: translateY(-40px);
      margin-bottom: 0;
      background: #fff;
      box-shadow: none;
      transition: all 800ms ease-in-out;


      padding-left: 28px;
      font-size: 18px;

      .card__title {    
        color: $darkblue;
        transition: all 1200ms ease-in-out;
      }
    }


    transform: translateY($bigCardOffset);

    &.slide {
      transform: translateY(0);
          transition: transform 800ms ease-in-out;
    }

    &.slideback {
      transition: none;
      transform: translateY($bigCardOffset);

    }
  

    //margin-bottom: $bigCardOffset;
  }


  &.slide {
    .card:first-child {
      
      transform: translateY(0);
      margin: 0;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08);

      .card__title {
        color: $primaryColor;
      }
      
    }
     
  }
  .card {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08);
    //border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .card:last-child {
    border-radius: 0 0 3px 3px;
  }

  .card:first-child {
    border-radius: 0 0 3px 3px;
  }
}

.arrows {
  position: absolute;
  top: 23px;
  right: 10px;
  
  @include bp(big) {
    top: 66px;
    right: 30px;
  }
  .icon {
    display: none;
    opacity: 0;

    
  }

  .move-up & {
    

    .icon-arrow-up {
      display: block;
      color: #5fbe00;
      font-size: 18px;
      animation-name: arrowAnimationUp;
      animation-duration: 5s;
      animation-timing-function: ease-out;

      @include bp(big) {
        font-size: 27px;
      }

    }
  }

  .move-down & {

    .icon-arrow-down {
      display: block;
      color: #d10f41;
      font-size: 18px;
      animation-name: arrowAnimationDown;
      animation-duration: 5s;
      animation-timing-function: ease-out;

      @include bp(big) {
        font-size: 27px;
      }
    }
  }
}

.card-list--live div:nth-child(n+11) {
  display: none;
}

.panel--1 .card--first {
  .card__title {
    color: $darkblue;
    transition: none;
  }
}

.panel--2 .card-list .card-inner {
  opacity: 0;
}